import React, {Component} from 'react';
import TopHeader from '../template/TopHeader';
import SideNav from '../template/SideNav';
import {bookingActions, hairdresserActions, userActions} from "../../_actions";
import {connect} from "react-redux";
import DatePicker from "react-datepicker";
import dateFormat from "dateformat";
import PhoneInput from "react-phone-number-input";
import moment from 'moment';

//var ObjectId = require('mongoose').Types.ObjectId;

    class EditClient extends Component {
        constructor(props) {
            super(props);

            this.state = {
                name:'',
                first_name:'',
                last_name:'',
                email:'',
                phoneNumber:'',
                note:'',
                dob:'',
                error:'',
                isEmail:true,
                showEmail:''
            }
            this.editClient=this.editClient.bind(this);
            this.selectDob=this.selectDob.bind(this);
            this.cancelClient=this.cancelClient.bind(this);
            this.onChanged=this.onChanged.bind(this);
            this.getPhoneNumber=this.getPhoneNumber.bind(this);

        }

        componentDidMount() {
            var self = this;
            let params = {
                defaultRedirectTo:localStorage.getItem('defaultRedirectTo')
            };
            if (localStorage.getItem('accessToken')) {
                self.props.dispatch(userActions.profile(params))
            }else{
                this.props.history.push('/')
            }
            let {dispatch} = this.props;
            let hairdresserId = localStorage.getItem('userId');
            self.props.dispatch(hairdresserActions.getHairdresserClientDetail({
                'userId': this.props.match.params.id,
                'hairdresserId':localStorage.getItem('userId'),
                'bookingType':self.state.bookingType,
            }))
            .then(function (response) {
                console.log('client response', response);
                if(response.data.data.clientDetail){
                    let phoneNumber=response.data.data.clientDetail.phoneNumber?self.getPhoneNumber(response.data.data.clientDetail.phoneNumber):"";
                    console.log("phoneNumber",phoneNumber);

                    let email=response.data.data.clientDetail.email;
                    let emailArr=email.split('@');
                    if(emailArr[1]==='beuapp.com'){
                        self.setState({
                            isEmail:false,
                            showEmail:''
                        })
                    }else{
                        self.setState({
                            showEmail:email
                        })
                    }
                    self.setState({
                        first_name:response.data.data.clientDetail.first_name&&response.data.data.clientDetail.first_name!=""?response.data.data.clientDetail.first_name:response.data.data.clientDetail.name,
                        last_name:response.data.data.clientDetail.last_name,
                        email:response.data.data.clientDetail.email,
                        phoneNumber:phoneNumber,
                        note:response.data.data.clientDetail.note,
                    })
                    if(response.data.data.clientDetail.dob&&response.data.data.clientDetail.dob!==""){
                        self.setState({
                            dob:new Date(moment(response.data.data.clientDetail.dob,'DD-MM-YYYY'))
                        })
                    }

                }
            })
        }

        editClient(){
            let self = this;
            let params = {};
            params.hairdresserId = localStorage.getItem('userId');
            params.userId = this.props.match.params.id;
            params.name=this.state.first_name+" "+this.state.last_name;
            params.first_name=this.state.first_name;
            params.last_name=this.state.last_name;
            params.email=this.state.email;
            params.phoneNumber=this.state.phoneNumber;
            params.note=this.state.note;
            params.dob=this.state.dob?dateFormat(this.state.dob, 'dd-mm-yyyy'):""

            if (this.state.first_name === "" || this.state.first_name === undefined) {
                this.setState({
                    fNameError: 'Please enter first name'
                })
            }
            // else if (this.state.last_name === "" || this.state.last_name === undefined) {
            //     this.setState({
            //         lNameError: 'Please enter last name'
            //     })
            // }
            // else if (this.state.email === "" || this.state.email === undefined) {
            //     this.setState({
            //         emailError: "Please enter email address"
            //     })
            // }
            else if (this.state.email !== ""&&!this.validateEmail(this.state.email)) {
                this.setState({
                    emailError: 'Please enter a valid email address'
                })
            } else {
                self.props.dispatch(hairdresserActions.editClient(params))
                    .then(function (response) {
                        //console.log('client response', response);

                        if (response.data.errorCode) {
                            if(response.data.errorCode==51){
                                self.setState({
                                    emailError: response.data.messages
                                })
                            }else{
                                self.setState({
                                    error: response.data.messages
                                })
                            }

                        } else {
                            self.props.history.push('/clients')
                        }
                    })
            }
        }

        selectDob(e){
            this.setState({dob:e})
        }

        cancelClient(){
            this.props.history.push('/clients/'+this.props.match.params.id)
        }

        onChanged(e) {
            this.setState({ [e.target.name]: e.target.value, copied: false });

            if (e.target.name === 'email') {
                this.setState({
                    showEmail:e.target.value
                })
                if (!this.validateEmail(e.target.value)) {
                    this.setState({
                        emailError: 'Please enter a valid email address'
                    })
                } else {
                    this.setState({
                        emailError: ''
                    })
                }
            }

            if (e.target.name === 'first_name') {
                if (e.target.value === '' || e.target.value === null) {
                    this.setState({
                        fNameError: ''
                    })
                } else {
                    this.setState({
                        fNameError: '',
                    })
                }
            }

            if (e.target.name === 'last_name') {
                if (e.target.value === '' || e.target.value === null) {
                    this.setState({
                        lNameError: ''
                    })
                } else {
                    this.setState({
                        lNameError: '',
                    })
                }
            }
        }

        validateEmail(email) {
            const pattern = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
            const result = pattern.test(email);
            return result;
        }

        handlePhoneChange = val => {
            this.setState({phoneNumber: val})
            // if(!isValidPhoneNumber(val)) {
            //     this.setState({
            //         phoneNumberError: 'Please enter a valid phone number'
            //     })
            // }
        }

        getPhoneNumber(phoneNumber){
            let phone=phoneNumber.toString()
            console.log("phone",phone);
            if(phone.length===5){
                console.log("phone1",phone);
                if (phone[0] === 0||phone[0] === "0") {
                    console.log("phone1.1",phone);
                    return "+44"+phone.substr(1)
                }else{
                    console.log("phone1.2",phone);
                    return "+44"+phone
                }
            }else {
                console.log("phone2",phone);
                if (phone[0] === 0||phone[0] === "0") {
                    console.log("phone2.1",phone);
                    return "+44"+phone.substr(1)
                } else if (phone[0] === 4||phone[0] === "4") {
                    console.log("phone2.2",phone);
                    return "+" + phone
                }  else if (phone[0] === 4||phone[0] === "+") {
                    console.log("phone2.3",phone);
                    return phone
                } else {
                    console.log("phone2.4",phone);
                    return "+44"+phone
                }
            }
        }




render() {
    console.log("this.prop", this.props);
    return(
        <div className="h_n">
            <div className="body_container cm_bg_white">
                <TopHeader title={this.props.clientDetail.clientDetail?"Edit "+this.props.clientDetail.clientDetail.name:""} {...this.props} />
                <SideNav {...this.props}/>


                <div className="container">

                    <div className="client_table p-4 mt-0">
                        {this.state.error ?
                            <span className="cm_error_msg">{this.state.error}</span> : ''}
                        <div className="row">

                            <div className="col-md-6 form-group">
                                <label>First Name</label>
                                <input type="text" className="form-control" name="first_name" value={this.state.first_name} onChange={this.onChanged} />
                                {this.state.fNameError ?
                                    <span className="cm_error_msg">{this.state.fNameError}</span> : ''}
                            </div>

                            <div className="col-md-6 form-group">
                                <label>Surname</label>
                                <input type="text" className="form-control" name="last_name" value={this.state.last_name} onChange={this.onChanged} />
                                {this.state.lNameError ?
                                    <span className="cm_error_msg">{this.state.lNameError}</span> : ''}
                            </div>

                            <div className="col-md-6 form-group">
                                <label>Email</label>
                                <input type="email" className="form-control" name="email" value={this.state.showEmail} onChange={this.onChanged} />
                                {this.state.emailError ?
                                    <span className="cm_error_msg">{this.state.emailError}</span> : ''}
                            </div>

                            <div className="col-md-6 form-group cm_PhoneInput">
                                <label>Mobile Number</label>
                                {/*<input type="number" className="form-control" name="phoneNumber" value={this.state.phoneNumber} onChange={this.onChanged}/>*/}
                                <PhoneInput
                                    international={true}
                                    defaultCountry="GB"
                                    country={"GB"}
                                    className="form-control"
                                    placeholder="Phone Number"
                                    name="phoneNumber"
                                    placeholder="Enter phone number"
                                    value={this.state.phoneNumber}
                                    onChange={this.handlePhoneChange}/>
                            </div>

                            <div className="col-md-12 form-group">
                                <label>Birthday</label>
                                <div className="cm_full_date">
                                <DatePicker
                                    dateFormat="dd/MM/yyyy"
                                    selected={this.state.dob}
                                    onChange={this.selectDob.bind(this)}
                                    className="form-control"
                                />
                                </div>
                            </div>

                            {/*<div className="col-md-6 form-group">*/}
                            {/*    <label>Birth Year</label>*/}
                            {/*    <input type="text" className="form-control" />*/}
                            {/*</div>*/}


                            <div className="col-md-12 form-group">
                                <label>Notes</label>
                                <textarea className="form-control" rows="6" name="note" value={this.state.note} onChange={this.onChanged}></textarea>
                            </div>



                        </div>

                    </div>

                            <div className="text-right mt-4">
                                <button className="btn btn-outline-dark pl-5 pr-5" onClick={this.cancelClient.bind(this)}>Cancel</button>
                                <button className="btn btn-dark ml-4 pl-5 pr-5" onClick={this.editClient.bind(this)}>Save</button>
                            </div>



                </div>



            </div>
        </div>
    )
}
    }

function mapStateToProps(state) {
    return {
        ...state
    };
}

export default connect(mapStateToProps)(EditClient);
